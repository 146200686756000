@charset "UTF-8";

@font-face {
    font-family: Nexa;
    src: url("/assets/fonts/Nexa.woff") format("woff");
    src: url("/assets/fonts/Nexa.woff") format("woff"),
         url("/assets/fonts/Nexa.ttf") format("truetype");
}

@font-face {
    font-family: Corbel;
    src: url("/assets/fonts/Corbel.woff") format("woff");
    src: url("/assets/fonts/Corbel.woff") format("woff"),
         url("/assets/fonts/Corbel.ttf") format("truetype");
}

@font-face {
  font-family: "heyu";
  src:url("/assets/fonts/icons/heyu.eot");
  src:url("/assets/fonts/icons/heyu.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/icons/heyu.woff") format("woff"),
  url("/assets/fonts/icons/heyu.ttf") format("truetype"),
  url("/assets/fonts/icons/heyu.svg#heyu") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "heyu" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "heyu" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\61";
}
.icon-twitter:before {
  content: "\62";
}
.icon-linkedin:before {
  content: "\63";
}
