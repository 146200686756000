// ANIMATION
.animated {
    animation-duration:1s;
    animation-fill-mode:both;
    &.late{ animation-duration:1.5s; }
    &.later{ animation-duration:2s; }
}
@-webkit-keyframes fadeInUp {
  from {
        opacity:0;
        -webkit-transform:translate3d(0, 100%, 0);
        transform:translate3d(0, 100%, 0);
    }

    to {
        opacity:1;
        -webkit-transform:none;
        transform:none;
    }
}
@-moz-keyframes fadeInUp {
 from {
       opacity:0;
       -moz-transform:translate3d(0, 100%, 0);
       transform:translate3d(0, 100%, 0);
    }

    to {
        opacity:1;
        -moz-transform:none;
        transform:none;
    }
}

@-o-keyframes fadeInUp {
 from {
        opacity:0;
        -o-transform:translate3d(0, 100%, 0);
        transform:translate3d(0, 100%, 0);
    }

    to {
        opacity:1;
        -o-transform:none;
        transform:none;
    }
}

@-ms-keyframes fadeInUp {
   from {
        opacity:0;
        -ms-transform:translate3d(0, 100%, 0);
        transform:translate3d(0, 100%, 0);
    }  

    to {
        opacity:1;
        -ms-transform:none;
        transform:none;
    }  
}

@keyframes fadeInUp {
  from {
        opacity:0;
        -webkit-transform:translate3d(0, 100%, 0);
        transform:translate3d(0, 100%, 0);
    }

    to {
        opacity:1;
        -webkit-transform:none;
        transform:none;
    }
}
.fadeInUp {
    -webkit-animation-name:fadeInUp;
    animation-name:fadeInUp;
    visibility:visible !important;
}
