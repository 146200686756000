/*
 * CUSTOM STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance:styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */


  /**********/
 /* HEADER */
/**********/
.header{
    height:101vh;
    background-size:cover; background-position:center center; background-repeat:no-repeat; background-attachment:fixed;
    .intro{ position:absolute; top:57%; transform:translateY(-50%); z-index:500; }
    .title{ 
        margin-bottom:-2rem; margin-top:7rem;
        letter-spacing:.5rem; line-height:unset; 
    }
    .text-wrapper{ color:$white; }
    .overlay{ height:101vh; opacity:.4; }
    .logo{ 
        max-width:150px; margin-top:50px;
        position:relative; z-index:500;
    }
}


  /********/
 /* TEXT */
/********/
.text-wrapper{
    height:100%; padding:5rem;
    &.story{ padding:5rem 3rem; margin:0 2rem; }
}


  /***********/
 /* NETWORK */
/***********/
.network{
    height:200px; min-height:0;
    position:relative; z-index:500;
}
.mobile-network{
    width:100%; height:100%;
    position:absolute; top:0; left:0; z-index:500;
    .network-item-wrapper{
        &:nth-child(1){ width:75px; height:75px; top:5%; left:80%; }
        &:nth-child(2){ width:75px; height:75px; top:70%; left:75%; }
        &:nth-child(3){ width:75px; height:75px; top:65%; left:0%; }
    }
}
.network-item-wrapper{
    width:100px; height:100px;
    transition:all .7s cubic-bezier(0, 0, 0, 1);
    position:absolute;
    &:nth-child(1){ width:125px; height:125px; top:-20%; left:30%; }
    &:nth-child(2){ width:100px; height:100px; top:55%; right:10%; }
    &:nth-child(3){ width:75px;  height:75px;  top:40%; left:0%; }
    &.big{
        .network-item{
            width:100%; height:100%;
            top:0; left:0;
            box-shadow:0px 0px 0px 10px rgba(0,0,0,0.1);
            .network-img{ padding:5px; }
        }
    }
    .network-item{
        width:0; height:0;
        position:absolute; top:50%; left:50%;
        border-radius:100%;
        background:#F59791;
        content:"";
        transition:all 1.3s cubic-bezier(0, 0, 0, 1);
        .network-img{
            width:100%; height:100%; padding:0;
            position:absolute; top:0; left:0;
            border-radius:100%;
            transition:all 1.3s cubic-bezier(0, 0, 0, 1);
        }
    }
}


  /***********/
 /* CONCEPT */
/***********/
.concept{
    .title{ 
        width:100%; margin-bottom:0;
        position:absolute; bottom:0; left:0; 
    }
}


  /***************/
 /* APP SHOWOFF */
/***************/
.app-showoff-wrapper{
    position:relative;
    .text-wrapper{ width:100%; padding:3rem; }
    .spacer{ height:60vh; &.small{ height:30vh; } }
}


  /******************/
 /* MASSIVE MOBILE */
/******************/
.massive-mobile-wrapper{
    width:100%; height:100%;
    position:absolute;
    .massive-mobile{
        max-width:400px; margin:-65% auto 0 auto;
        display:block;
    }
}
.scrollmagic-pin-mobile{ max-width:400px; }
.scrollmagic-pin-mobile-screen{ 
    width:68%; height:100%;
    position:absolute !important; top:1.6% !important; left:16% !important; 
}
.scroll-indicator-wrapper{
    width:100%; height:100%;
    position:absolute;
    .scroll-indicator-line{
        width:3px; height:100%;
        position:relative; left:50%; transform:translateX(-50%);
        background:$light-gray;
        overflow:hidden;
    }
}



  /*******/
 /* APP */
/*******/
.app{
    .text-container{ margin-bottom:-15rem; margin-top:10rem; }
}


  /************/
 /* DOWNLOAD */
/************/
.download-wrapper{
    position:relative;
    color:$white;
    .overlay{ opacity:1; background:linear-gradient(220deg, $primary-color, $secondary-color, $tertiary-color, $tertiary-color); }
    .subtitle{ color:$white; font-size:3rem; }
    .text-container{ margin-top:20rem; }
    .fullwidth-img{ height:auto; position:relative; }
    .app-icon{
        position:relative;
        .app-img{ 
            max-width:100px;
            border-radius:15px;
            -webkit-box-reflect:below 1rem -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent) , to(rgba(250, 250, 250, 0.1)));
            &:after{
                background:radial-gradient(circle, rgba($white, 0), $tertiary-color);
                position:absolute;
                content:"";
            }
        }
        .rad-grad{
            width:70%; height:70%;
            position:absolute; top:30px; left:50%; z-index:-1; transform:translateX(-50%);
            background:radial-gradient( rgba(126, 90, 155, 0.5) 10%, rgba(255,255,255, 0) 50%); background-position:0px 50px; background-repeat:no-repeat;
        }
    }
    .store-badges{ 
       margin:9rem 0;
        .badge{ max-width:150px; margin:1rem; }
    }
    .hovering-mobile{ position:absolute; top:0; left:0; }
}


  /**********/
 /* FOOTER */
/**********/
.footer{
    background:linear-gradient(270deg, $tertiary-color -20%, $quaternary-color 333%, rgba(126, 90, 155, 0.5));
    font-size:1.2rem; line-height:10rem;
    .footer-item{ margin:0 1rem; color:$white; }
}


@include breakpoint( medium ) { /* min-width:520px */
      /**********/
     /* HEADER */
    /**********/
    .header{
        .title{  margin-bottom:4rem; margin-top:4rem; }
    }


      /********/
     /* TEXT */
    /********/
    .text-wrapper{
        &.story{ padding:5rem; }
    }
}

@include breakpoint( large ) { /* min-width:960px */
      /**********/
     /* HEADER */
    /**********/
    .header{
        .intro{ top:50%; width:100%; }
        .text-wrapper{ height:100%; padding:0; margin:0 auto; }
        .title{  margin-bottom:4rem; margin-top:4rem; }
    }


      /********/
     /* TEXT */
    /********/
    .text-wrapper{ 
        max-width:535px; 
        &.story{ margin:0; }
    }


      /***********/
     /* NETWORK */
    /***********/
    .network{ height:auto; }
    .network-item-wrapper{
        &:nth-child(1){ width:150px; height:150px; top:75%; left:30%; opacity:1; }
        &:nth-child(2){ width:75px;  height:75px;  top:45%; left:10%; opacity:.5; }
        &:nth-child(3){ width:125px; height:125px; top:50%; left:13%; opacity:1; }
        &:nth-child(4){ width:125px; height:125px; top:35%; left:50%; opacity:1; }
        &:nth-child(5){ width:100px; height:100px; top:10%; left:30%; opacity:1; }
        &:nth-child(6){ width:75px;  height:75px;  top:15%; left:80%; opacity:.8; }
        &:nth-child(7){ width:75px;  height:75px;  top:0%;  left:60%; opacity:.5; }
    }


      /***********/
     /* CONCEPT */
    /***********/
    .concept{
        .title{ margin-bottom:10rem; }
    }


      /***************/
     /* APP SHOWOFF */
    /***************/
    .app-showoff-wrapper{
        .text-wrapper{ padding:10rem; }
    }


      /******************/
     /* MASSIVE MOBILE */
    /******************/
    .massive-mobile-wrapper{
        .massive-mobile{ margin:-55% auto 0 auto;}
    }


      /*******/
     /* APP */
    /*******/
    .app{
        .text-container{ margin-bottom:-20rem; }
    }


      /************/
     /* DOWNLOAD */
    /************/
    .download-wrapper{
        .subtitle{ font-size:5rem; line-height:6rem; }
        .fullwidth-img{ margin-top:35rem; }
        .app-icon{
            .app-img{ max-width:150px; }
        }
        .store-badges{ margin:20rem 0; }
    }
}

@include breakpoint( mlarge ) { /* min-width:1024px */
    .scrollmagic-pin-mobile-screen{ top:1.9% !important; }
}

@include breakpoint( xlarge ) { /* min-width:1200px */
      /**********/
     /* HEADER */
    /**********/
    .header{
    }

      /********/
     /* TEXT */
    /********/
    .text-wrapper{ 
        padding:10rem; 
        &.story{ padding:10rem; }
    }


      /******************/
     /* MASSIVE MOBILE */
    /******************/
    .massive-mobile-wrapper{
        .massive-mobile{ margin:-50% auto 0 auto;}
    }

    .scrollmagic-pin-mobile-screen{ top:2.2% !important; }

}

@include breakpoint( xxlarge ) { /* min-width:1600px */
    .scrollmagic-pin-mobile-screen{ top:2.6% !important; }
}
