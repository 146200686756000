/*
 * OVERWRITES 
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */

/* zf Switch */
.switch-active, .switch-inactive{
    width:55%;
    text-align:center;
    display:inline-block;
}
.switch-active{left:0;}
.switch-inactive{right:0;}

.column{ min-height:300px; z-index:100; }

@include breakpoint( medium ) { /* min-width: 520px */
    
}

@include breakpoint( large ) { /* min-width: 960px */
    .column{ min-height:350px; }

}

@include breakpoint( xlarge ) { /* min-width: 1200px */
    .column{ min-height:520px; }

}

@include breakpoint( xxlarge ) { /* min-width: 1600px */

}
