/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Disable warnings in terminal
$show-warnings: false;

// Import settings_frontend.scss. For overwriting foundation variables
@import 'fonts';
@import 'settings';

// Import all Foundation components
@import '../../../../node_modules/foundation-sites/scss/foundation.scss';

// Include the right mixins
@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
//  @include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
//  @include foundation-accordion;
//  @include foundation-accordion-menu;
//  @include foundation-badge;
//  @include foundation-breadcrumbs;
//  @include foundation-button-group;
@include foundation-callout;
//  @include foundation-close-button;
//  @include foundation-drilldown-menu;
//  @include foundation-dropdown;
//  @include foundation-dropdown-menu;
//  @include foundation-flex-video;
//  @include foundation-label;
//  @include foundation-media-object;
@include foundation-menu;
//  @include foundation-menu-icon;
//  @include foundation-off-canvas;
//  @include foundation-orbit;
//  @include foundation-pagination;
//  @include foundation-progress-bar;
//  @include foundation-slider;
//  @include foundation-sticky;
//  @include foundation-reveal;
//  @include foundation-switch;
//  @include foundation-table;
//  @include foundation-tabs;
//  @include foundation-thumbnail;
//  @include foundation-title-bar;
//  @include foundation-tooltip;
//  @include foundation-top-bar;

@if $global-flexbox {
    @include foundation-flex-classes;
}

// For grouping your css in mixins
@import 'mixins';
// General classes for returning styles
@import 'general_styles';
// To overwrite default style
@import 'overwrites';

// Import animation style
@import 'animation';

// Import custom style
@import 'custom';