/*
 * GENERAL CLASSES 
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 */

/* Default browser style override */
$body-font-size:rem-calc(16);

body{font-size:$body-font-size;}
p{font-size:$body-font-size;}
ul, ol, dl{font-size:$body-font-size;}
select {appearance:none !important;}
*::selection{ color:$white; background-color:$primary-color; }

/* Alert boxes */
.alert-box{
    @include alert;
    .close{ @include alert-close; }
    &.radius{border-radius:$alert-radius;}
    &.primary{ @include alert-style($primary-color); }
    &.secondary{ @include alert-style($secondary-color); }
    &.success{ @include alert-style($success-color); }
    &.alert{ @include alert-style($alert-color); }    
    &.warning{ @include alert-style($warning-color); } 
    &.alert-close{opacity:0;}
    ul{margin-bottom:0;}
    a{
        color:$white; text-decoration:underline;
        &:hover{text-decoration:none;}
    }
}

.relative{ position:relative; }


  /********/
 /* MAIN */
/********/
.main{ 
    overflow:hidden; 
    transition:filter .7s ease;
    &.is-blured{ filter:blur(8px); }
}


  /********/
 /* MENU */
/********/
.top-bar{
    width:100%; height:45px; 
    position:fixed; top:-100px; left:0; z-index:10000;
    transition:top .7s ease, height 0s ease .7s;
    &:after{
        width:100%; height:45px;
        position:absolute; top:0; left:0; z-index:-1;
        background:rgba($black, 0.3);
        transition:background .7s ease;
        content:"";
    }
    &.is-at-top{ top:0; }
    &.is-open{ 
        height:100%; transition:height 0s ease 0s; 
        &:after{ background:transparent; }
    }
    .overlay{
        height:100%; 
        z-index:-1;
        background:transparent;
        opacity:1;
        transition:background .7s ease;
        &.is-open{ background:rgba($black, 0.3); }
    }
    .row{
        max-width:120rem; margin:0 auto;
        .logo{ max-width:75px; margin-top:15px; margin-left:10px; }
    }

    // Menu
    .menu{
        width:0%; height:0%;
        position:fixed; top:0; left:0; z-index:-1;
        &.is-open{ 
            width:100%; height:50%;
            top:50%; transform:translateY(-50%);
        }
        .menu-item{
            max-width:unset;
            .menu-anchor{ color:$white; font-family:'Nexa', sans-serif; font-size:1.8rem; text-transform:uppercase; letter-spacing:0.5rem; justify-content:center; }
        }
    }

    // Menu toggle
    .menu-toggle {
        width:35px; height:35px; margin-top:10px;
        float:right; position:relative;
        display:inline-block;
        cursor:pointer;
        &.is-open {
            .hamburger {
                .line {
                    width:0px;
                    &:nth-child(1){ transition-delay:0s; }
                    &:nth-child(2){ transition-delay:.125s; }
                    &:nth-child(3){ transition-delay:.25s; }
                }
            }
            .cross {
                .line { &:nth-child(1){ height:30px; transition-delay:.625s; } }
                .line { &:nth-child(2){ width:30px; transition-delay:.375s; } }
            }
        }
        .hamburger {
            height:100%; width:100%;
            position:absolute;
            .line {
                width:25px; height:2px; margin:5px 0;
                position:relative;
                &:nth-child(1){ transition-delay:.5s; }
                &:nth-child(2){ transition-delay:.625s; }
                &:nth-child(3){ transition-delay:.75s; }
            }
        }
        .cross {
            height:56px; width:56px;
            position:absolute;
            transform:rotate(45deg);
            .line{
                &:nth-child(1){
                    width:2px; height:0;
                    position:absolute; top:15px; left:5px;
                }
                &:nth-child(2){
                    width:0%; height:2px;
                    position:absolute; left:-9px; top:29px;
                    transition-delay:.25s;
                }
            }
        }
    }
    .line {
        display:block;
        background:$white;
        transition:height .5s ease, width .5s ease;
    }
}


  /*********/
 /* TITLE */
/*********/
.title{ 
    margin:4rem 0; 
    position:relative; z-index:100;
    color:$white; font-family:'Nexa', sans-serif; font-size:2rem; text-transform:uppercase; letter-spacing:1rem; line-height:10rem;
    &.download{
        margin-top:0;
        font-size:2rem; line-height:3rem; letter-spacing:.5rem;
    }
}
.subtitle{
    margin-bottom:3rem;
    font-size:2.6rem; line-height:3rem; font-family:'Nexa', sans-serif;
    &.grad{ @include gradient-text('linear-gradient(130deg, #58B7A2, #4AB4CA, #A983BB, #F59791)'); }
}


  /******/
 /* BG */
/******/
.bg-img{ background-size:cover; background-position:center center; background-repeat:no-repeat; }
.bg-light{ background:$white; }
.bg-grad{ background:linear-gradient(220deg, $primary-color, $secondary-color, $tertiary-color, $quaternary-color); color:$white; }


  /*******/
 /* IMG */
/*******/
.fullwidth-img{
    width:100%; height:100%;
    position:absolute; left:0; top:0;
    background-size:cover; background-position:center center;
}


  /***********/
 /* OVERLAY */
/***********/
.overlay{
    width:100%; height:100%;
    position:absolute; z-index:0;
    opacity:.6;
    background:linear-gradient(220deg, $primary-color, $secondary-color, $tertiary-color, $quaternary-color);
    &.mad-grad{
        background:
          linear-gradient(40deg, $quaternary-color 10%, transparent 30%),
          linear-gradient(40deg, $tertiary-color, transparent 80%),
          linear-gradient(345deg, $secondary-color, transparent 60%),
          linear-gradient(345deg, $primary-color, transparent 60%),
          linear-gradient(345deg, $primary-color, transparent 60%);
    }
}


  /*********/
 /* ARROW */
/*********/
.arrow{
    width:20px; height:20px; margin:-10px 0 0 -10px;
    position:absolute; bottom:10%; left:50%; z-index:500;
    transform:rotate(45deg); 
    border-left:none; border-top:none; border-right:2px $white solid; border-bottom:2px $white solid;
    &:before{
        width:10px; height:10px; margin:-5px 0 0 -5px;
        position:absolute; top:50%; left:50%;
        border-left:none; border-top:none; border-right:1px $white solid; border-bottom:1px $white solid;
        animation-duration:2s; animation-iteration-count:infinite; animation-name:arrow;
        content:'';
    }
}


  /*********/
 /* ROW */
/*********/
.row{ &.max-w{ max-width:120rem; } }


  /*********/
 /* SLOPE */
/*********/
.slope{
    width:100%; height:65px;
    overflow:hidden; z-index:100;
    border-right:0px solid transparent; border-bottom:65px solid $white;
}


@include breakpoint( medium ) { /* min-width:520px */
    
}

@include breakpoint( large ) { /* min-width:960px */
      /********/
     /* MENU */
    /********/
    .top-bar{
        height:100px;
        position:absolute; top:0;
        &:after{ height:0; }
        &.is-fixed{ 
            position:fixed; transform:translateY(-100px); 
            &:after{ height:75px; }
            .row{ .logo{ max-width:100px; } }
            .menu{
                .menu-item{ 
                    margin:2.8rem 2.5rem 0 2.5rem; 
                    .menu-anchor{ font-size:1.4rem; }
                }
            }
        }
        &.is-at-top{  top:100px; }
        .row{ .logo{ max-width:200px; margin-top:25px; } }
        .menu{
            width:unset; height:unset;
            position:static; z-index:10000;
            .menu-item{
                margin:5rem 2.5rem 0 2.5rem;
                .menu-anchor{ font-size:1.8rem; letter-spacing:0.5rem; }
            }
        }
    }


      /*********/
     /* TITLE */
    /*********/
    .title{  
        margin:10rem 0;
        font-size:5rem; 
        &.download{
            margin-top:0;
            font-size:6rem; line-height:9rem; letter-spacing:1rem;
        }
    }
    .subtitle{ font-size:3.1rem; line-height:1.5; }


      /***********/
     /* OVERLAY */
    /***********/
    .overlay{
        &.mad-grad{
            background:
              linear-gradient(40deg, $quaternary-color 10%, transparent 30%),
              linear-gradient(40deg, $tertiary-color, transparent 80%),
              linear-gradient(345deg, $secondary-color, transparent 60%),
              linear-gradient(345deg, $primary-color, transparent 60%),
              linear-gradient(345deg, $primary-color, transparent 60%);
        }
    }


      /*********/
     /* ARROW */
    /*********/
    .arrow{
        width:30px; height:30px; margin:-15px 0 0 -15px;
        &:before{
            width:15px; height:15px; margin:-7.5px 0 0 -7.5px;
        }
    }


      /*********/
     /* SLOPE */
    /*********/
    .slope{ height:170px; border-bottom:170px solid $white; }
}

@include breakpoint( xlarge ) { /* min-width:1200px */

}

@include breakpoint( xxlarge ) { /* min-width:1600px */

}



/* Keyframes */
@keyframes arrow{
    0%{
        opacity:1;
    }
    100%{
        opacity:0;
        transform:translate(-10px, -10px);
    }
}