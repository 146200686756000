/*
* CUSTOM MIXINS
*
* Some things in CSS are a bit tedious to write, especially with CSS3 and the many vendor prefixes that exist. 
* A mixin lets you make groups of CSS declarations that you want to reuse throughout your site. 
* You can even pass in values to make your mixin more flexible. 
*/

/* ALERT BOXES (FROM FOUNDATION 5) */
// Default alert base.
@mixin alert-base {
    border-style:$alert-border-style;
    border-width:$alert-border-width;
    display:block;
    font-weight:$alert-font-weight;
    margin-bottom:$alert-bottom-margin;
    position:relative;
    padding:$alert-padding-top $alert-padding-opposite-direction $alert-padding-bottom $alert-padding-default-float;
    font-size:$alert-font-size;
    transition:opacity $alert-transition-speed $alert-transition-ease;
}

// Add alert styles
@mixin alert-style($bg:$primary-color) {
    // Lightness percentage of the background color.
    $bg-lightness:lightness($bg);

    background-color:$bg;
    border-color:scale-color($bg, $lightness:$alert-function-factor);

    // Text color based on the background color.
    @if $bg-lightness > 70% { color:$alert-font-color-alt; }
    @else                   { color:$alert-font-color; }
}

// We use this to create the close button.
@mixin alert-close {
    font-size:$alert-close-font-size;
    padding:$alert-close-padding;
    line-height:.9;
    position:absolute;
    top:$alert-close-top;
    margin-top:-($alert-close-font-size / 2);
    right:$alert-close-position;
    color:$alert-close-color;
    opacity:$alert-close-opacity;
    background:$alert-close-background;
    &:hover,
    &:focus { opacity:$alert-close-opacity-hover; }
}

// Single mixin to create the alert
@mixin alert($bg:$primary-color, $radius:false) {
    @include alert-base;
    @include alert-style($bg);
}


@mixin gradient-text($gradient) {
    @supports(mix-blend-mode:lighten) {
        position:relative;
        display:inline-block;
        color:$black;
        background:$white;
        mix-blend-mode:multiply;

        &::before {
            position:absolute; top:0; right:0; bottom:0; left:0;
            display:block;
            content:'';
            background:unquote($gradient);
            pointer-events:none;
            mix-blend-mode:screen;
        }
    }
}